
@font-face {font-family: 'SabonLTStd-Roman';src: url('webfonts/3728DC_0_0.eot');src: url('webfonts/3728DC_0_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3728DC_0_0.woff2') format('woff2'),url('webfonts/3728DC_0_0.woff') format('woff'),url('webfonts/3728DC_0_0.ttf') format('truetype'),url('webfonts/3728DC_0_0.svg#wf') format('svg');}


@font-face {font-family: 'ProximaNova-Bold';src: url('webfonts/3728DC_1_0.eot');src: url('webfonts/3728DC_1_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3728DC_1_0.woff2') format('woff2'),url('webfonts/3728DC_1_0.woff') format('woff'),url('webfonts/3728DC_1_0.ttf') format('truetype'),url('webfonts/3728DC_1_0.svg#wf') format('svg');}


@font-face {font-family: 'ProximaNova-Regular';src: url('webfonts/3728DC_2_0.eot');src: url('webfonts/3728DC_2_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3728DC_2_0.woff2') format('woff2'),url('webfonts/3728DC_2_0.woff') format('woff'),url('webfonts/3728DC_2_0.ttf') format('truetype'),url('webfonts/3728DC_2_0.svg#wf') format('svg');}


@font-face {font-family: 'ProximaNova-Semibold';src: url('webfonts/3728DC_3_0.eot');src: url('webfonts/3728DC_3_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3728DC_3_0.woff2') format('woff2'),url('webfonts/3728DC_3_0.woff') format('woff'),url('webfonts/3728DC_3_0.ttf') format('truetype'),url('webfonts/3728DC_3_0.svg#wf') format('svg');}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.hide{
    display:none;
}

.input-button{
    margin-right: 20px !important;
}

.img-block {
  margin-bottom: 2px;
  margin-top: 2px;
}
.header{
    margin-bottom:0px;
    margin-top:15px;
}

.nav-item{
  margin-top: 20px;
}

.nav-item a{
  margin-right: 20px;
  padding-bottom: 10px;
  text-decoration: none;
  font-size: 18px;
  color: black;
}

.nav-item a:hover {
  color: #3f51b5;
}

/* Add a color to the active/current link */
.nav-item a.active {
  color: #3f51b5;
  border-bottom: 2px solid #3f51b5;
}

.split{
    padding-left:40px;
    padding-right:10px;
}

.intonation-select{
  padding-top: 10px;
  padding-bottom: 10px;
}

.red {
  color: red;
}
.gray {
  color: gray;
}
.green {
  color: green;
}
.orange {
  color: #FF8C00;
}

.strike{
  color: gray;
  text-decoration: line-through;
}

.inline {
  display: inline-block;
}

.faq{
    background-color: #efefef;
    padding-bottom: 40px;
}

.faq-button{
    margin-right:40px !important;
}

.subtitle {
    font-style: italic;
    font-size:16px;
}

.faq h1{
    font-family: ProximaNova-Bold
}

.faq .questions{
    margin-top:20px;
}

.input-type{
    padding-bottom: 40px;
}

audio{
    max-width: 200px;
}

.result{
    border-left:1px solid #efefef;
}

.result-empty {
    text-align: center;
    padding-top: 200px;
    color: grey;
}

.result-empty .icon{
    font-size:60px;
}

.result-empty .text{
    font-size:20px;
}


.footer{
    text-align: center;
    color: #aaaaaa;
    font-size: 12px;
    margin: 20px 0 10px 0;
}

.words-block {
  text-align: center;
  padding-top:10px;
  font-size: 28px;
  text-align: left;
}
.word {
  display: inline-block;
  margin: 0px 10px 0px 10px;
}
.word .score {
  font-size: 24px;
  text-align: center;
  display: block;
}

.stress{
    font-size: 14px;
    color: gray;
    margin-left:2px;
}


.phoneme-word {
  margin: 0px 10px 0px 10px;
  font-size: 20px;
}
.phoneme-text {
  font-size: 20px;
}

.category-select{
    margin-bottom:20px;
}
.example-paper{
    font-size:20px;
    font-family: ProximaNova-Semibold;
}

.json{
    max-height: 600px;
    overflow-x: hidden;
    overflow-x: scroll;
}


.intonation-outer{
  width: 100%; 
  overflow-x: scroll;
  overflow-y: hidden
}

.intonation-inner{
  width: 100%;
  height: 500px
}

#canvas {
  width:100%;
}